// import { Injectable } from '@angular/core';
// import { CookieService } from 'ngx-cookie-service';
// import jwt_decode from 'jwt-decode';

// @Injectable({
//   providedIn: 'root'
// })
// export class SharedServiceService {
//   cookiesExpirationInterval: any;
//   tokenData: any;
//   token: string;
//   constructor(private cookieService: CookieService) {
//     this.token = this.cookieService.get('token');
//   }

//   startInterval() {
//     this.cookiesExpirationInterval = setInterval(() => {
//       var currentDate = new Date();
//       var futureDate = new Date(currentDate.getTime() + 5000);
//       this.cookieService.set('token', this.token, futureDate);
//     }, 2000);
//   }

//   stopInterval() {
//     clearInterval(this.cookiesExpirationInterval);
//   }

//   getUserData() {
//     console.log(this.tokenData);
//     let k = JSON.parse(this.tokenData.Userrecord);
//     let obj = {
//       ddlText: k.DDLText,
//       districtID: k.DistrictID,
//       districtName: k.DistrictName,
//       ldapUserID: k.LDAPUserID,
//       pageTitle: k.PageTitle,
//       personEmail: k.PersonEmail,
//       personFirstName: k.PersonFirstName,
//       personId: k.PersonId,
//       personLastName: k.PersonLastName,
//       personType: k.PersonType,
//       returnPath: k.ReturnPath,
//       roleID: k.RoleID,
//       roleName: k.RoleName,
//       schoolID: k.SchoolID,
//       schoolName: k.SchoolName,
//     }
//     if (this.token) {
//       return obj;
//     } else {
//       return null;
//     }
//   }

//   getDecodedAccessToken(): any {
//     try {
//       return jwt_decode(this.token);
//     } catch (Error) {
//       return null;
//     }
//   }

//   setTokenInCookie(t: string) {
//     this.cookieService.set('token', t);
//   }

//   getToken() {
//     return this.cookieService.get('token');
//   }

//   removeToken() {
//     this.stopInterval();
//     this.cookieService.delete('token', '/');
//     this.token = "";
//   }

//   startDecoding() {
//     this.tokenData = this.getDecodedAccessToken();
//   }
// }

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { jwtDecode } from "jwt-decode";
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ResponseModel } from '../Models/ResponseModel';

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  cookiesExpirationInterval: any;
  tokenData: any;
  token: string;
  constructor(
    private cookieService: CookieService,
    private dataservice: DataService
  ) {
    // this.token = this.cookieService.get('token');
    // this.tokenData = this.getDecodedAccessToken(this.token);
  }

  startInterval() {
    this.cookiesExpirationInterval = setInterval(() => {
      // var minutesToAdd = 2;
      // var currentDate = moment().format('MMMM Do YYYY Z');
      // var futureDate = moment().add(2, 'm');
      // var k = futureDate.date()+'/'+futureDate.month()+'/'+futureDate.year()+', '+futureDate.hour()+':'+futureDate.minute()+':'+futureDate.second()
      // this.cookieService.set('token', this.token, new Date(k));

      var minutesToAdd = 5;
      var currentDate = new Date();
      var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
      this.cookieService.set('token', this.getToken(), futureDate, '/');
    }, 2000);
  }

  stopInterval() {
    clearInterval(this.cookiesExpirationInterval);
  }

 
  getUserData() {
    this.token = this.getToken();
    if (this.token) {
      this.tokenData = this.getDecodedAccessToken(this.token);
      let user = JSON.parse(this.tokenData.Userrecord);
      // console.info({ user });
      let obj = {
        ddlText: user.DDLText,
        districtID: user.DistrictID,
        districtName: user.DistrictName,
        ldapUserID: user.LDAPUserID,
        pageTitle: user.PageTitle,
        personEmail: user.PersonEmail,
        personFirstName: user.PersonFirstName,
        personId: user.PersonId,
        personLastName: user.PersonLastName,
        personType: user.PersonType,
        returnPath: user.ReturnPath,
        roleID: user.RoleID,
        roleName: user.RoleName,
        roleCategoryType: user.RoleCategoryType,
        schoolID: user.SchoolID,
        schoolName: user.SchoolName,
      };
      return obj;
    }
  }
  

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }

  setTokenInCookie(t: string) {
    this.token = t;
    this.tokenData = this.getDecodedAccessToken(this.token);
    // console.info({ td: this.tokenData });
    this.cookieService.set('token', t, 0, '/');
  }

  getToken() {
    return this.cookieService.get('token');
  }

  removeToken() {
    if (localStorage.getItem('MobileApp') != 'Y') {
      this.stopInterval();
    }
    this.cookieService.deleteAll('/');
  }

  SetLoginCount(username) {
    var minutesToAdd = 10;
    var currentDate = new Date();
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var obj = {
      LoginCount: 0,
      Username: username,
    };
    var res =
      this.cookieService.get('LoginCount') != ''
        ? JSON.parse(this.cookieService.get('LoginCount'))
        : null;
    if (res == null) {
      obj.LoginCount = 1;
      this.cookieService.set('LoginCount', JSON.stringify(obj), futureDate);
    } else {
      if (obj.Username == username) {
        obj.LoginCount = parseInt(res.LoginCount) + 1;
        if (res.LoginCount <= 10) {
          this.cookieService.set('LoginCount', JSON.stringify(obj), futureDate);
        }
      }
    }
  }

  CheckForMultipleLogins() {
    var res =
      this.cookieService.get('LoginCount') != ''
        ? JSON.parse(this.cookieService.get('LoginCount'))
        : null;
    if (res != null && res != undefined) {
      if (res.LoginCount >= 10) {
        return false;
      }
    }
    return true;
  }

  adminGuidePDFDownload(value) {
    return <Observable<any>>(
      this.dataservice.DownloadFilePdf(
        'menu/getAdminGuidePDFDownload?value=' + value
      )
    );
  }

  GetLoginPersonMessage(districtID, personId, personType, roleID) {
    return <Observable<ResponseModel>>(
      this.dataservice.GetData(
        `authenticate/GetLoginPersonMessage?districtID=${districtID}&personId=${personId}&personType=${personType}&roleID=${roleID}`
      )
    );
  }

  UpdateShowPersonMessage(personId) {
    return <Observable<ResponseModel>>(
      this.dataservice.GetData(
        'authenticate/UpdateShowPersonMessage?personId=' + personId
      )
    );
  }

  IsStripeEnabled() {
    return <Observable<ResponseModel>>(
      this.dataservice.GetData('stripe/IsStripeEnabled')
    );
  }
}
